<template>
  <div smart-container>
      <template v-if="regionCode == 410103000000000002">
          <SmartPark></SmartPark>
      </template>
      <template v-else-if="regionCode == 110102000000000088">
          <BuildingSceneIndex></BuildingSceneIndex>     
      </template>
      <template>

      </template>
  </div>
</template>

<script>
import SmartPark from '@/views/SmartPark/SmartPark.vue';
import BuildingSceneIndex from '@/views/BuildingScene/BuildingSceneIndex.vue'
export default {
    name:'Smart',
    data(){
        return{
            regionCode:null
        }
    },
    created(){
        this.regionCode=this.$vc.getCurrentRegion().code;
        console.log(this.regionCode);
    },
    components:{
        SmartPark,
        BuildingSceneIndex
    }
}
</script>

<style>

</style>