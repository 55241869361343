<template>
  <div class="builDing-container" v-if="parkVisible">
    <div id="container_3D" @click="onDocumentMouseDown" v-if="parkVisible">
      <div id="layer" v-if="!(speed == 100)">
        <div class="progressStrip">
          <div class="progressImg">
            <div :style="'width:' + speed + '%'">
              <img src="@/assets/lou.png" alt="">
            </div>
          </div>
          <div class="progressBar">
            <span class="white" :style="'width:' + speed + '%'">
            </span>
          </div>
          <div class="tips">Loading...{{speed + '%'}}</div>
        </div>
      </div>
      <!-- 场景是否漫游按钮 -->
      <div class="switch">
        <img
          class="panorama"
          src="@/assets/niaokan.png"
          alt=""
          v-if="state != 1"
          @click.stop="panorama"
        />
        <img
          class="roam"
          src="@/assets/manyou.png"
          alt=""
          v-else
          @click.stop="roam"
        />
      </div>
      <div class="zoom" @click="fullScreenView">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-menuziyuanldpi"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as Three from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import OrbitControls from "three-orbitcontrols";
import { Capsule } from "three/examples/jsm/math/Capsule.js";
import { Octree } from "three/examples/jsm/math/Octree.js";
export default {
  data() {
    return {
      parkVisible: true,
      //场景
      scene: null,
      //导入的场景格式模式
      loader: null,
      clock: null,
      playerVelocity: null,
      playerDirection: null,
      playerCollider: null,
      worldOctree: null,
      controls: null,
      keyStates: {},
      renderer: null,
      speed:0,
      modelUrl: {
        // mainBuilding: "/common/models/haikoudalou/海口中心.glb",
        mainBuilding: "https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/haikou.glb"
      },
      // 天空图
      urls: [
        "https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/px.jpg",
        "https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/nx.jpg",
        "https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/py.jpg",
        "https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/ny.jpg",
        "https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/pz.jpg",
        "https://cosmos-static.oss-cn-shanghai.aliyuncs.com/triangle/img/nz.jpg",
      ],
      // 1:是鸟瞰，2:是漫游
      state: 1,
    };
  },
  methods: {
    onDocumentMouseDown() {

    },
    //初始化场景
    init() {
      //初始化场景
      this.scene = new Three.Scene();
      // gltf/glb格式模型
      this.loader = new GLTFLoader();
      //时间对象，用于获取渲染之间的间隔
      this.clock = new Three.Clock();

      this.playerVelocity = new Three.Vector3();
      this.playerDirection = new Three.Vector3();

      //设置摄像机的位置数据
      this.playerCollider = new Capsule(
        new Three.Vector3(0, 0.35, -100),
        new Three.Vector3(0, 2, -100),
        0.5
      );
      this.worldOctree = new Octree();

      // 加载模型
      if (!this.modelUrl["mainBuilding"]) {
        this.parkVisible = false;
        return;
      }

      this.loader.load(
        this.modelUrl["mainBuilding"],
        (gltf) => {
          this.scene.add(gltf.scene);
          // this.worldOctree.fromGraphNode( gltf.scene );
          gltf.scene.traverse((child) => {
            if (child.isMesh ) {
            	child.castShadow = true;
            	child.receiveShadow = true;
            	if ( child.material.map ) {
            		child.material.map.anisotropy = 8;
            	}
              child.material.emissive =  child.material.color;
              child.material.emissiveMap = child.material.map;
            }

          });
        },
        (pro) => {
          if (pro.loaded / pro.total == 1) {
            this.speed = 99.99;
            setTimeout(() => {
              this.speed = 100;
            }, 2000);
            return;
          }
          this.speed = ((pro.loaded / pro.total) * 100).toFixed(2);
        },
        () => {
          // console.log(err, 'cuopwu ')
        }
      );

      // 添加环境光
      const ambient = new Three.AmbientLight(0xffffff);
      this.scene.add(ambient);

      // 设置天空盒
      this.scene.background = new Three.CubeTextureLoader().load(
        this.urls,
        () => {},
        () => {},
        () => {}
      );

      // 添加摄像机
      this.camera = new Three.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000000
      );
      // 设置位置
      this.camera.position.set(100, 40, -100);
      // 设置角度
      // 千万不要动！不然漫游y方向就错了！⬇
      this.camera.rotation.order = "YXZ";
      // 创建控件（用户鸟瞰时旋转、缩放、移动）
      this.controls = new OrbitControls(
        this.camera,
        document.getElementById("container_3D")
      ); // 创建控件对象
      this.controls.maxPolarAngle = 1.3;
      this.controls.minDistance = 100;
      this.controls.maxDistance = 800;

      this.renderer = new Three.WebGLRenderer();
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      // 插入canvas对象
      document
        .getElementById("container_3D")
        .appendChild(this.renderer.domElement);
      document
        .getElementById("container_3D")
        .addEventListener("keydown", (event) => {
          this.keyStates[event.code] = true;
        });
      document
        .getElementById("container_3D")
        .addEventListener("keyup", (event) => {
          this.keyStates[event.code] = false;
        });
    },

    // 触发按键事件
    moveControls(deltaTime) {

      // 设置移动的速度
      const speed = 100;
      if (this.keyStates["KeyW"]) {
        this.playerVelocity.add(
          this.getForwardVector().multiplyScalar(speed * deltaTime)
        );
      }
      if (this.keyStates["KeyS"]) {
        this.playerVelocity.add(
          this.getForwardVector().multiplyScalar(-speed * deltaTime)
        );
      }
      if (this.keyStates["KeyA"]) {
        this.playerVelocity.add(
          this.getSideVector().multiplyScalar(-speed * deltaTime)
        );
      }
      if (this.keyStates["KeyD"]) {
        this.playerVelocity.add(
          this.getSideVector().multiplyScalar(speed * deltaTime)
        );
      }
      if (this.keyStates["Tab"]) {
        document
          .getElementById("container_3D")
          .addEventListener("mousedown", this.mouseClick);
      }
      if (this.keyStates["Digit1"]) {
        this.playerCollider = new Capsule(
          new Three.Vector3(30, 272, -6),
          new Three.Vector3(30, 273, -6),
          0.5
        );
         this.camera.rotation.y = -1;
         //this.camera.rotation.x = -0.5;
      }
      if (this.keyStates["Digit2"]) {
        this.playerCollider = new Capsule(
          new Three.Vector3(80, 25, 0),
          new Three.Vector3(80, 26, 0),
          0.5
        );
      }
      if (this.keyStates["Digit3"]) {
        this.playerCollider = new Capsule(
          new Three.Vector3(0, 0.35, -100),
          new Three.Vector3(0, 2, -100),
          0.5
        );
      }
      if (this.keyStates["Digit4"]) {
        this.playerCollider = new Capsule(
          new Three.Vector3(100, 38.65, -100),
          new Three.Vector3(100, 40, -100),
          0.5
        );
        this.panorama();
        document.exitPointerLock();
      }
    },
    updatePlayer(deltaTime) {
      const damping = Math.exp(-3 * deltaTime) - 1;
      this.playerVelocity.addScaledVector(this.playerVelocity, damping);
      const deltaPosition = this.playerVelocity
        .clone()
        .multiplyScalar(deltaTime);
      this.playerCollider.translate(deltaPosition);
      this.playerCollitions();
      this.camera.position.copy(this.playerCollider.end);
    },
    // 将鼠标指针锁定在指定元素上
    mouseClick() {
      document.getElementById("container_3D").requestPointerLock();
      if (this.pattern) {
        document
          .getElementById("container_3D")
          .removeEventListener("mousedown", this.mouseClick);
        document.exitPointerLock();
        this.pattern = false;
      } else {
        this.pattern = true;
      }
    },
    animate() {
      if (this.state === 2) {
        const deltaTime = Math.min(0.05, this.clock.getDelta()) / 5;
        this.moveControls(deltaTime);
        this.updatePlayer(deltaTime);
      }
      this.switchBackground();
      this.rafId = requestAnimationFrame(this.animate);
      //console.log('主场景渲染中');
      this.renderer.render(this.scene, this.camera);
      this.setScale();
    },
    // 碰撞检测 (目前移除，谨慎加，浏览器容易崩)
    playerCollitions() {
      const result = this.worldOctree.capsuleIntersect(this.playerCollider);
      if (result) {
        this.playerVelocity.addScaledVector(
          result.normal,
          -result.normal.dot(this.playerVelocity)
        );
        this.playerCollider.translate(
          result.normal.multiplyScalar(result.depth)
        );
      }
    },
    //  切换背景
    switchBackground() {
      if (this.keyStates["Digit5"]) {
        if (this.antiShake) {
          return;
        }
        this.antiShake = true;
        if (this.scene.background.image.length > 0) {
          this.scene.background = new Three.CubeTextureLoader().load(
            [],
            () => {},
            () => {},
            () => {}
          );
        } else {
          this.scene.background = new Three.CubeTextureLoader().load(
            this.urls,
            () => {},
            () => {},
            () => {}
          );
        }
        let timer = setTimeout(() => {
          this.antiShake = false;
          clearTimeout(timer);
        }, 300);
        timer;
      }
      // TODO: 做完视频后移除代码
      if (this.keyStates["Digit6"]) {
        console.log(this.camera);
        this.camera.position.y -= 1;
      }
      if (this.keyStates["Digit7"]) {
        console.log(this.camera);
        this.camera.position.y += 1;
      }
    },
    // 鼠标控制方向
    setRotation(event) {
      this.camera.rotation.y -= event.movementX / 500;
      console.log( this.camera.rotation.y);
      this.camera.rotation.x -= event.movementY / 500;
    },
    // 调整缩放倍数
    setScale() {
      let clWidth = document.body.clientWidth;
      if (clWidth < 1366) {
        this.scale = "scale(0.5)";
      } else if (clWidth < 1600) {
        this.scale = "scale(0.6)";
      } else if (clWidth < 2000) {
        this.scale = "scale(0.8)";
      } else {
        this.scale = "";
      }
    },
    // 漫游
    roam() {
      this.state = 2;
      this.playerCollider = new Capsule(
        new Three.Vector3(0, 0.35, -100),
        new Three.Vector3(0, 2, -100),
        0.5
      );
      this.camera.rotation.y = 3;
      this.controls.enabled = false;
      document
        .getElementById("container_3D")
        .addEventListener("mousemove", this.setRotation);
      this.camera.aspect =
        document.getElementById("container_3D").innerWidth /
        document.getElementById("container_3D").innerHeight;
      document
        .getElementById("container_3D")
        .addEventListener("mousedown", this.mouseClick);
    },
    getForwardVector() {
      this.camera.getWorldDirection(this.playerDirection);
      this.playerDirection.y = 0;
      this.playerDirection.normalize();
      return this.playerDirection;
    },
    getSideVector() {
      this.camera.getWorldDirection(this.playerDirection);
      this.playerDirection.y = 0;
      this.playerDirection.normalize();
      this.playerDirection.cross(this.camera.up);
      return this.playerDirection;
    },
    // 设置全屏
    fullScreenView() {
      const container_3D = document.getElementById('container_3D');
      if (container_3D.requestFullscreen) {
          container_3D.requestFullscreen();
      } else if (container_3D.mozRequestFullScreen) {
          container_3D.mozRequestFullScreen();
      } else if (container_3D.webkitRequestFullscreen) {
          container_3D.webkitRequestFullscreen();
      } else if (container_3D.msRequestFullscreen) {
          container_3D.msRequestFullscreen();
      }
    },
    // 鸟瞰
    panorama() {
      this.state = 1;
      this.camera.position.set(100, 40, -100);
      this.camera.rotation.x = -0.2756427992162654;
      this.camera.rotation.y = 2.356194490192345;
      this.controls.enabled = true;
      document
        .getElementById("container_3D")
        .removeEventListener("mousemove", this.setRotation);
      document
        .getElementById("container_3D")
        .removeEventListener("mousedown", this.mouseClick);
    },
     // 判断全屏
    judgeZoom() {
      document.addEventListener('fullscreenchange', function(e){
        if (document.fullscreenElement) {
          document.getElementsByClassName("zoom")[0].style.opacity = 0;
        } else {
          document.getElementsByClassName("zoom")[0].style.opacity = 1 ;
        }
      });
    },
  },
  mounted() {
    this.init();
    this.animate();
    this.judgeZoom();
    let timebar = setTimeout(() => {
      this.fullScreenView();
      clearTimeout(timebar);
    });
  },
};
</script>

<style lang='stylus'>
#container_3D
    width 100%
    height 100%
    position relative
    canvas
        width 100% !important
        height 100% !important
    #layer
        height 100%
        width 100%
        background-color rgba(0,0,0,0.5)
        position fixed
        top 0
        left 0
        .progressStrip
            width 520px
            height 70px
            position absolute
            top 50%
            left 50%
            border-radius 16px
            transform translate(-50%, -50%)
            .progressImg
                width 485px
                height 100px
                margin-left 17px
                div
                    height 100px
                    overflow hidden
                    img
                        width 485px
                        height 100px
            .progressBar
                width 520px
                height 70px
                background #999
                border 5px solid #fff
                border-radius 14px
                .white
                    height 100%
                    background-color #fff
                    display inline-block
                    border-radius 6px
            .tips
                color #fff
                font-size 44px
                text-align center
    .switch
        width 34px
        height 34px
        position absolute
        right 10px
        bottom 10px
        .panorama
            height 100%
            width 100%
        .roam
            height 100%
            width 100%
    .zoom
        width 30px
        height 30px
        position absolute
        right 10px
        top 0
        font-size 30px
.dialog-content
  padding 30px 30px 0 30px
  .row
    width 100%
    margin-bottom 30px
    font-size 38px
    .title-info
      width 100%
      text-align center
.dialog-container
    padding 20px 20px 0 20px
    .survey
        border-bottom 1px solid #fff
        display flex
        align-items center
        .enterprise
            width 180px
            text-align center
            .num
                color #FFD301
                font-size 28px
                margin-bottom 0
            .text
                color #fff
                font-size 22px
                margin-bottom 0
        .line
            width 1px
            height 62px
            background-color #fff
        .user
            @extends .enterprise
    .sex
        margin-top 20px
        .sex_title
            position relative
            margin-bottom 15px
            img
                width 350px
                height 40px
            .text
                position absolute
                font-size 24px
                color #fff
                top 0
                margin-left 10px
        .man
            margin-bottom 10px
            display flex
            align-items center
            .list
                .icon
                    margin-right 7px
            .proportion
                text-align center
                .percentage
                    font-size 14px
                    color #00E6FF
                    margin-bottom 10px
                .text
                    font-size 12px
                    color #fff
                    margin-bottom 0
        .woman
            @extends .man
            .proportion
                .percentage
                    color #FF5A5A
    .ageDistribution
        .sex_title
            position relative
            margin-bottom 15px
            img
                width 350px
                height 40px
            .text
                position absolute
                font-size 24px
                color #fff
                top 0
                margin-left 10px
        .chart
            .chart-item
                margin-bottom 15px
                .chart-item-info
                    display flex
                    align-items center
                    margin-bottom 4px
                    span
                        margin-right 20px
                        color #fff
                        font-size 14px
                    .square
                        display inline-block
                        width 10px
                        height 10px
                        margin-right 15px
                        background-color #FFD301
                .line
                    width 100%
                    height 6px
                    .line-bg
                        width 100%
                        height 100%
                        display flex
                        align-items center
                        .line-color
                            height 100%
                            background-color  #FFD301
                            border-radius 3px
                            margin-right 10px
                            transition all 1s linear
                        .num
                            color #FFD301
                            font-size 14px
    .rentalRate
        border-bottom 1px solid #fff
        margin-bottom 20px
    .floorOccupancyRate
        .title
            position relative
            margin-bottom 15px
            img
                width 350px
                height 40px
            .text
                position absolute
                font-size 24px
                color #fff
                top 0
                margin-left 10px
        .floorList
            .chart
                .chart-item
                    margin-bottom 15px
                    .chart-item-info
                        display flex
                        align-items center
                        margin-bottom 4px
                        span
                            margin-right 20px
                            color #fff
                            font-size 14px
                        .square
                            display inline-block
                            width 10px
                            height 10px
                            margin-right 15px
                            background-color #FFD301
                    .line
                        width 100%
                        height 6px
                        display flex
                        align-items center
                        .line-bg
                            width 85%
                            height 100%
                            background-color #fff
                            border-radius 3px
                            margin-right 10px
                            display inline-block
                            .line-color
                                height 100%
                                background-color  #FFD301
                                border-radius 3px
                                margin-right 10px
                                transition all 1s linear
                        .num
                            color #FFD301
                            font-size 14px
.abnormal
    position absolute
    height 40px
    padding 0 20px
    background-color #FFE6E6
    border-radius 30px
    color #FF5A5A
    line-height 40px
    font-size 28px
    text-align center
#tenant_3D
    height 200px
    width 400px
.lease-situation-chart
    display flex
    align-items center
    .info
        width 100px
        .room
            text-align center
            .num
                color #FFD301
                font-size 14px
                margin-bottom 0
            .text
                color #FFF
                font-size 14px
        .area
            @extends .room
.row_img
    position absolute
    display flex
    justify-content flex-start
    left 20px
    bottom 20px
    width 1100px
    height 160px
    padding 20px
    background-color rgba(255, 255, 255, 0.3)
    border-radius 5px
    .row_img_item
        text-align center
        &:not(:last-of-type)
            margin-right 40px
        img
            width 80px
            height 80px
            margin-bottom 10px
        .row_img_item_type
            width 120px
            height 40px
            border-radius 20px
            font-size 24px
            line-height 40px
            background-color #FFE6E6
            color #FF5A5A
        .green
            background-color #D8FFF8
            color #00B694
.col_img
    position absolute
    top 20px
    right 20px
    width 110px
    height 420px
    padding 15px
    border-radius 5px
    background-color rgba(255, 255, 255, 0.3)
    .col_img_item
        width 80px
        height 80px
        &:not(:last-of-type)
            margin-bottom 24px
</style>
